<template>
  <b-card>
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="4" v-if="!isUpdate">
            <b-form-group id="input-created">
              <label for="created">Created At:</label>
              <datePicker v-model="editedItem.created_at" :config="dpOptions"></datePicker>
            </b-form-group>
          </b-col>
          <b-col :cols="isUpdate ? 3 : 4">
            <b-form-group id="input-amount">
              <label for="amount">Amount: <span class="text-danger">*</span></label>
              <b-form-input
                id="amount"
                v-model="editedItem.amount"
                :state="invalidAmount"
                type="number"
                aria-describedby="input-amount-feedback"
                placeholder="100000"
              ></b-form-input>
              <b-form-invalid-feedback id="input-amount-feedback">
                {{ invalidAmountMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="4" v-if="!isUpdate">
            <b-form-group id="input-campaign">
              <label for="campaign">Campaign: <span class="text-danger">*</span></label>
              <b-form-select
                id="campaign"
                v-model="editedItem.campaign_id"
                :options="campaignList"
                :state="invalidCampaign"
                aria-describedby="input-campaign-feedback"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Campaign --</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="input-campaign-feedback">
                {{ invalidCampaignMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col :cols="isUpdate ? 3 : 4">
            <b-form-group id="input-bank">
              <label for="bank">Bank: <span class="text-danger">*</span></label>
              <b-form-select id="bank" v-model="editedItem.bank_id" :options="bankList" :state="invalidBank">
                <template v-slot:first
                  ><b-form-select-option :value="null">-- Please select Bank --</b-form-select-option></template
                ></b-form-select
              >
              <b-form-invalid-feedback id="input-bank-feedback">
                {{ invalidBankMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col :cols="isUpdate ? 3 : 4">
            <b-form-group id="input-bank-number">
              <label for="bank-number">Bank Number: <span class="text-danger">*</span></label>
              <b-form-input
                id="bank-number"
                v-model="editedItem.bank_number"
                type="text"
                placeholder="0983 3423 2342 2134"
                :state="invalidBankNumber"
              ></b-form-input>
              <b-form-invalid-feedback id="input-bank-feedback">
                {{ invalidBankNumberMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col :cols="isUpdate ? 3 : 4">
            <b-form-group id="input-bank-account">
              <label for="bank-account">Bank Account Name: <span class="text-danger">*</span></label>
              <b-form-input
                id="bank-account"
                v-model="editedItem.bank_account"
                type="text"
                placeholder="Nguyen Van A"
                :state="invalidBankAccount"
              ></b-form-input>
              <b-form-invalid-feedback id="input-bank-feedback">
                {{ invalidBankAccountMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Campaign Commission List</h4>
      </b-col>
      <b-col cols="2">
        <b-form-group id="input-campaign-filter" label="Campaign" label-for="campaign-filter">
          <b-form-select id="campaign-filter" v-model="filter_campaign" :options="campaignList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="8"></b-col>
      <b-col cols="2">
        <b-button block variant="primary" class="float-right" @click="addItem" style="margin-top: calc(1rem + 19px)"
          >Add</b-button
        >
      </b-col>
    </b-row>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(created_at)="item">
        {{ formatDate(new Date(item.value), true) }}
      </template>
      <template v-slot:cell(amount)="item">
        {{ formatPrice(item.value) }}
      </template>
      <template v-slot:cell(bank_name)="item">
        {{ item.item.bank_name }} <br />
        {{ item.item.bank_number }} <br />
        {{ item.item.bank_account }}
      </template>
      <template v-slot:cell(status)="item" :v-if="item.value == 1">
        <b-badge class="mr-1" v-if="item.value == 1" variant="success">Success</b-badge>
        <b-badge class="mr-1" v-else-if="item.value == 0" variant="warning">Pending</b-badge>
        <b-badge class="mr-1" v-else variant="danger">Rejected</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
      </template>
    </b-table>
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Rows per page:</label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
// import { mapState } from "vuex";
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import ApiService from '@/common/api.service.js';
import Swal from 'sweetalert2';
export default {
  name: 'campaigns',
  components: {
    datePicker
  },
  data() {
    return {
      isBusy: false,
      // Filter table
      filter_campaign: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      searchFocus: false,
      // Table header
      headers: [
        { label: 'Created At', key: 'created_at', class: 'text-center' },
        { label: 'Campaign', key: 'campaign_name', class: 'text-center' },
        { label: 'Amount', key: 'amount', class: 'text-center' },
        { label: 'Bank Info', key: 'bank_name', class: 'text-center' },
        { label: 'Status', key: 'status', class: 'text-center' },
        { label: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Table data
      items: [],
      modalTitle: '',
      // Pagination
      rows: 0,
      perPage: 10,
      currentPage: 1,
      errors: [],
      // Modal data
      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'YYYY/MM/DD',
        sideBySide: true
      },
      campaignList: [],
      bankList: [],
      editedItem: {
        campaign_id: null,
        bank_id: null
      },
      invalidAmount: null,
      invalidAmountMessage: '',
      invalidCampaign: null,
      invalidCampaignMessage: '',
      invalidBank: null,
      invalidBankMessage: '',
      invalidBankNumber: null,
      invalidBankNumberMessage: '',
      invalidBankAccount: null,
      invalidBankAccountMessage: '',
      isUpdate: false
    };
  },
  created() {
    this.fetchCampaign();
    this.fetchBank();
    this.fetchData();
  },
  methods: {
    fetchCampaign() {
      ApiService.setHeader();
      ApiService.get('/partners/campaigns')
        .then((response) => {
          this.campaignList = response.data.data;
        })
        .catch((errors) => this.errors.push(errors));
    },
    fetchBank() {
      ApiService.setHeader();
      ApiService.get('/allbanks')
        .then((response) => {
          this.bankList = response.data.data;
        })
        .catch((errors) => this.errors.push(errors));
    },
    fetchData() {
      // this.loading = true;
      this.isBusy = true;
      ApiService.setHeader();
      ApiService.get(
        'campaigns/commissions',
        `?campaignId=${this.filter_campaign || ''}&limit=${this.perPage}&offset=${this.currentPage}`
      )
        .then((response) => {
          this.items = response.data.data.commissionList.map((commission) => {
            let campaign = this.campaignList.find((campaign) => campaign.value == commission.campaign_id);
            return {
              ...commission,
              campaign_name: campaign ? campaign.text : ''
            };
          });
          this.rows = response.data.data.total;
          this.isBusy = false;
        })
        .catch((errors) => {
          this.errors.push(errors);
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.dialog = true;
      this.isUpdate = false;
      this.modalTitle = 'Add Campaign Commission';
    },
    editItem: function (item) {
      this.modalTitle = 'Update Campaign Commission';
      this.isUpdate = true;
      let selectedBank = this.bankList.find((bank) => bank.text == item.bank_name);
      this.editedItem = {
        ...item,
        bank_id: selectedBank ? selectedBank.value : null
      };
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        campaign_id: null,
        bank_id: null
      };
      this.invalidAmount = null;
      this.invalidAmountMessage = '';
      this.invalidCampaign = null;
      this.invalidCampaignMessage = '';
      this.invalidBank = null;
      this.invalidBankMessage = '';
      this.invalidBankNumber = null;
      this.invalidBankNumberMessage = '';
      this.invalidBankAccount = null;
      this.invalidBankAccountMessage = '';
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Validate required fields
      let allValidated = true;
      if (!this.editedItem.amount || this.editedItem.amount == '') {
        this.invalidAmount = false;
        this.invalidAmountMessage = 'Please input amount';
        allValidated = false;
      }
      if (!this.editedItem.bank_number || this.editedItem.bank_number == '') {
        this.invalidBankNumber = false;
        this.invalidBankNumberMessage = 'Please input bank number';
        allValidated = false;
      }
      if (!this.editedItem.bank_account || this.editedItem.bank_account == '') {
        this.invalidBankAccount = false;
        this.invalidBankAccountMessage = 'Please input bank account';
        allValidated = false;
      }
      if (this.editedItem.bank_id === null) {
        this.invalidBank = false;
        this.invalidBankMessage = 'Please select bank';
        allValidated = false;
      }
      if (!this.isUpdate) {
        if (this.editedItem.campaign_id === null) {
          this.invalidCampaign = false;
          this.invalidCampaignMessage = 'Please select campaign';
          allValidated = false;
        }

        if (!this.editedItem.created_at) {
          this.editedItem.created_at = new Date();
        }
        if (!allValidated) {
          return;
        }
        ApiService.post('/campaigns/commissions', {
          ...this.editedItem,
          amount: +this.editedItem.amount,
          created_at: new Date(this.editedItem.created_at).toISOString()
        })
          .then((response) => {
            if (response.data.status) {
              Swal.fire({
                icon: 'success',
                title: 'Commisison Added',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                this.fetchData();
              });
            }
            this.close();
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Something wrong, try again',
              showConfirmButton: false,
              timer: 3000
            });
          });
      } else {
        if (!allValidated) {
          return;
        }
        let updateBody = {
          id: this.editedItem.id,
          amount: +this.editedItem.amount,
          bank_name: this.editedItem.bank_name,
          bank_number: this.editedItem.bank_number,
          bank_id: this.editedItem.bank_id
        };
        ApiService.put('/campaigns/commissions', updateBody)
          .then((response) => {
            if (response.status == 204) {
              Swal.fire({
                icon: 'success',
                title: 'Commisison Updated',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                this.fetchData();
              });
            }
            this.close();
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Something wrong, try again',
              showConfirmButton: false,
              timer: 3000
            });
          });
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Referrals'
      },
      { title: 'Campaign Commission', route: 'campaign-commissions' }
    ]);
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  }
};
</script>
